<template>
	<b-modal v-model="open" size="sm" centered no-close-on-esc no-close-on-backdrop hide-header modal-class="mui-animation" :fade="false">
		<div class="d-flex justify-content-center align-items-center text-center w-100">
			<h5>{{ title }}</h5>
		</div>
		<template #modal-footer>
			<div class="w-100">
				<div class="d-flex flex-row justify-content-center text-center gap-10">
					<b-button variant="custom-outline-gray" size="sm" class="btn-close" @click="closeModalOpen"> Close </b-button>
					<b-button variant="primary" size="sm" class="btn-confirm" @click="handleDeleteWasteItem"> Yes </b-button>
				</div>
			</div>
		</template>
	</b-modal>
</template>
<script>
import LanguageMessages from '@/mixins/languageMessages';
import GlobalMixin from '@/mixins/global.mixin';

export default {
	name: 'DeleteWastes',
	mixins: [LanguageMessages, GlobalMixin],
	props: {
		open: {
			type: Boolean,
			required: false
		},
		title: {
			type: String,
			required: false
		},
		typeDelete: {
			type: String,
			required: false
		}
	},
	watch: {},
	components: {},
	computed: {},
	data() {
		return {};
	},
	methods: {
		closeModalOpen() {
			this.$emit('closeModalOpen');
		},
		handleDeleteWasteItem() {
			if (this.typeDelete === 'OneItem') {
				return this.$emit('handleDeleteWasteItem');
			}
			if (this.typeDelete === 'AllItem') {
				return this.$emit('handleDeleteAllWastesSelected');
			}
		}
	}
};
</script>
<style scoped>
.gap-1 {
	gap: 5px;
}
.btn-close {
	width: 100px !important;
}
.btn-confirm {
	width: 100px !important;
}
</style>
